exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ajan-varaus-js": () => import("./../../../src/pages/ajan-varaus.js" /* webpackChunkName: "component---src-pages-ajan-varaus-js" */),
  "component---src-pages-galleria-js": () => import("./../../../src/pages/galleria.js" /* webpackChunkName: "component---src-pages-galleria-js" */),
  "component---src-pages-hinnasto-js": () => import("./../../../src/pages/hinnasto.js" /* webpackChunkName: "component---src-pages-hinnasto-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-magnetix-wellness-js": () => import("./../../../src/pages/magnetix-wellness.js" /* webpackChunkName: "component---src-pages-magnetix-wellness-js" */),
  "component---src-pages-tietoa-js": () => import("./../../../src/pages/tietoa.js" /* webpackChunkName: "component---src-pages-tietoa-js" */)
}

